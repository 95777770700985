export class LiveChat extends HTMLElement {

    connectedCallback () {
        this.license = this.getAttribute('license')
        this.loadLiveChat()
        this.innerHTML = `
            <div class="live-chat open-chat">
                <div class="loader-container none">
                  <div class="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <svg color="inherit" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M12.63,26.46H8.83a6.61,6.61,0,0,1-6.65-6.07,89.05,89.05,0,0,1,0-11.2A6.5,6.5,0,0,1,8.23,3.25a121.62,121.62,0,0,1,15.51,0A6.51,6.51,0,0,1,29.8,9.19a77.53,77.53,0,0,1,0,11.2,6.61,6.61,0,0,1-6.66,6.07H19.48L12.63,31V26.46"></path><path fill="#0059E1" d="M19.57,21.68h3.67a2.08,2.08,0,0,0,2.11-1.81,89.86,89.86,0,0,0,0-10.38,1.9,1.9,0,0,0-1.84-1.74,113.15,113.15,0,0,0-15,0A1.9,1.9,0,0,0,6.71,9.49a74.92,74.92,0,0,0-.06,10.38,2,2,0,0,0,2.1,1.81h3.81V26.5Z"></path></svg>
            </div>
        `
        document.querySelectorAll('.open-chat').forEach(el => {
            el.addEventListener('click', event => this.openChat());
        });
        this.templateStyle()
    }

    openChat () {
        this.querySelector('.loader-container').classList.remove('none')
        this.querySelector('svg').classList.add('none')
        LiveChatWidget.on('ready', this.onReady)
        LiveChatWidget.init()
    }

    onReady () {
        LiveChatWidget.call('maximize')
        document.querySelector('.live-chat').classList.add('none')
    }

    loadLiveChat() {
        window.__lc = window.__lc || {};
        window.__lc.license = this.license;
        window.__lc.integration_name = "manual_channels";
        window.__lc.product_name = "livechat";
        window.__lc.asyncInit = true;
        ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    }

    templateStyle () {
        const template = document.createElement('template')
        template.innerHTML = `<style>
                .none {
                    display: none !important;
                }
                .live-chat {
                    display: none;
                    align-items: center;
                    justify-content: center;
                    position: fixed;
                    bottom: 0;
                    right: 0;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    box-shadow: rgba(0, 0, 0, 0.125) 0px 0.362176px 0.941657px -1px,rgba(0, 0, 0, 0.18) 0px 3px 7.8px -2px;
                    background: #0059e1;
                    z-index: 999;
                    margin: 0.5em 0.5em 1em 1em;
                    cursor: pointer;
                }
                @media (min-width: 750px) {
                    .live-chat {
                        display: flex;
                    }
                }
                .live-chat svg {
                    width: 1em;
                    height: 1em;
                    font-size: 2rem;
                }
                .loader-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                }
                .loader {
                    display: flex;
                    justify-content: space-between;
                    width: 35px;
                }
                
                .loader div {
                    width: 6px;
                    height: 6px;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                    animation: grow-shrink 1.5s infinite;
                }
                .loader div:nth-child(1) {
                    animation-delay: 0s;
                }
                .loader div:nth-child(2) {
                    animation-delay: 0.3s;
                }
                .loader div:nth-child(3) {
                    animation-delay: 0.6s;
                }
                @keyframes grow-shrink {
                    0%, 100% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.5);
                     }
                }
            </style>`

        this.appendChild(template.content.cloneNode(true))
    }

}